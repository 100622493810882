<template>
<tbody class="table-font">
    <tr>
        <td><i class='bx bxs-plus-circle plus-info' @click="visitcommentbox()"></i> {{ (currentpage - 1) * noofrec + (index + 1) }} </td>
        <!-- <td>
            <span v-if="item.loanemi!=null && item.loanemi.loanaccount!=null">{{item.loanemi.loanaccount.manualid}}</span>
        </td> -->
        <td>
            <a
                v-if="item.loanemi.loanaccount != null"
                href="#"
                @click="loandetails(item.loanemi.loanaccount.id)">
                <div v-if="item.loanemi != null && item.loanemi.loanaccount != null">
                <span style="font-size: 13px">
                    {{ item.loanemi.loanaccount.manualid }}</span>
                </div>
            </a>
        </td>
        <td>
            <span
                v-if="
                item.loanemi != null &&
                item.loanemi.loanaccount != null &&
                item.loanemi.loanaccount.customer != null
                "
            >
                {{ item.loanemi.loanaccount.customer.name }}</span
            >
        </td>
        <td>
            {{
                item.loanemi.loanaccount.customer == null
                ? ""
                : getMasterLabelByType(
                    item.loanemi.loanaccount.customer.zoneid,
                    MASTER_ZONE_TYPES
                    )
            }}
        </td>
        <td>
            <display-mobiles
                v-if="item.loanemi.loanaccount.customer != null"
                :mobiles="item.loanemi.loanaccount.customer.mobiles"
            ></display-mobiles>
        </td>
        <td>
            <display-first-address
                v-if="item.loanemi.loanaccount.customer != null"
                :addresses="item.loanemi.loanaccount.customer.addressess"
            ></display-first-address>
        </td>
        <td>
            <div v-for="item2 in item.loanemi.loanaccount.saleitems" :key="item2.id">
                <div>
                    <span v-if="item2.product!=null && item2.product.category!=null">{{item2.product.category.name}}</span>
                    <span v-if="item2.product!=null && item2.product.attribute!=null">
                        <div v-for="company in item2.product.attribute" :key="company.id">
                        {{ getcompany(company.attribute_value_ids) }}
                        </div>
                    </span>
                    <span >{{item2.product.name}}</span>&nbsp;
                    <!-- <span >{{item2.product.modelno}}</span> -->
                </div>
            </div>
        </td>
        <td>
            <span v-if="item.loanemi != null" ><i class="bx bx-rupee"></i> {{ item.loanemi.tobepaid }}</span>
        </td>
        <td></td>
        <td>
            <span
                v-if="item.loanemi != null && item.loanemi.loanaccount != null"
                style="color:red;font-weight:700"
            >
                {{ item.loanemi.loanaccount.remainingamount }}
            </span>
        </td>
        <td>
            <span
                ><i class="bx bxs-calendar"></i>
                {{ moment(item.emidate).format("DD-MM-YYYY") }}</span
            >
        </td>
        <td>
            <span v-if="item.loanemi != null"
                ><i class="bx bxs-calendar"></i>
                {{ moment(item.scheduleddate).format("DD-MM-YYYY") }}</span
            >
        </td>
        <td>
            <button class="badge bg-sheme" @click="visitcommentbox()">
                <i class="fa fa-envelope"></i> {{ visitcomments(item) }}
            </button>
        <!-- <span class="badge btn-view" style="margin-top: 16px;" v-if="item.paymentregister!=null">{{item.paymentregister.name}}</span> -->
        </td>
        <td>
            <button class="badge bg-seagreen">
                <i class="bx bxs-calendar"></i> {{ bucket(item) }}
            </button>
        </td>
        <td class="text-center">
            <button :class="danger==true?'badge bg-danger':'badge bg-seagreen'">
                <i class="bx bx-calendar"></i> {{ diffdates(item) }}
            </button>
        </td>
        <td>
            <div
                v-if="
                item.loanemi.loanaccount != null &&
                item.loanemi.loanaccount.loanfollowed != null">
                {{ item.loanemi.loanaccount.loanfollowed.name }}
            </div>
        </td>
        <td><span v-if="item.loanemi != null && item.loanemi.loanaccount != null && item.loanemi.loanaccount.customer != null">
            {{ item.loanemi.loanaccount.customer.customer_type }} </span>
        </td>
        <td><span v-if="item.loanemi != null && item.loanemi.loanaccount != null && item.loanemi.loanaccount.customer != null && item.loanemi.loanaccount.customer.paymentmode != null">
            {{ item.loanemi.loanaccount.customer.paymentmode.name }} </span>
        </td>
        <td v-if="loginusercheck(157)">
            <div class="form-group">
                <select class="form-control" v-model="accounttype" @change="accountchange(item)">
                    <option :value="0">Due Acc.</option>
                    <option :value="1">Clear Acc</option>
                    <option :value="2">Deafulter Acc</option>
                    <option :value="3">Legal Acc</option>
                    <option :value="4">Concession Acc</option>
                </select>
            </div>
        </td>
    </tr>
    <tr v-if="visitcomment">
        <td colspan="18" style="background-color:#f2f2f2;padding:0px">
            <dues-comment :emiid="item.emiid" :loanid="item.loanemi.loanaccountid" 
            :overdueamount="getoverdueamount(item.loanemi.loanaccountid)"
            :comment="getcomment(item.emiid)"></dues-comment>
        </td>
    </tr>
</tbody>

</template>
<script>
import Constants from "../../components/utilities/Constants.vue";
import DisplayFirstAddress from "../../components/address/DisplayFirstAddress.vue";
import DisplayMobiles from "../../components/mobile/DisplayMobiles.vue";
import moment from "moment";

import { mapGetters } from "vuex";
import DuesComment from "./DuesComment.vue";
export default {
    mixins:[Constants],
    components:{ DisplayFirstAddress, DisplayMobiles, DuesComment },    
    props:['item','index','currentpage','noofrec','overdues','comments'],
    data() {
        return {
            visitcomment:false,
            moment:moment,
            accounttype:0,
            danger:false
        }
    },
    computed: {
        ...mapGetters(["attributes","scheduleloanemi"])
    },
   
    methods: {
        getoverdueamount(id){
            if(this.overdues!=null){
                return this.overdues.find(a=>{
                    if(a.loanaccountid==id){
                        return a.overdue
                    }
                })
            }
        },
        getcomment(id){
            if(this.comments!=null){
                return this.comments.find(a=>{
                    if(a.emiid==id){
                        return a
                    }
                })
            }
        },
        accountchange(item){
            console.log(this.accounttype)
            this.$store.commit("assignaccountstatus",item)
            this.$store.commit("assignloanaccounttype",this.accounttype)
            this.$modal.show('loanaccount');
        },
        diffdates(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "days");
            if (diffInDays > 15) {
                this.danger=true
                return diffInDays;
            } else if(diffInDays <= 15) {
                this.danger=false
                return diffInDays;
            }else{
                this.danger=false
                return 0;
            }
            
        },
        bucket(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "months");
            if (diffInDays < 0) {
                return 0;
            } else {
                return diffInDays;
            }
        },
        visitcomments(item) {
            if (item != null && item.loanemi.visitcomments != null) {
                return item.loanemi.visitcomments.length;
            }
        },
        loandetails(id) {
            let param = { id: id };
            this.$http.post("api/loan/details", param)
                .then((response) => this.processloanResponse(response.data))
                .catch((err) => {
                console.log(err);
                });
        },
        processloanResponse(data) {
            this.$store.commit("assignedititem", data);
            this.$store.commit("assignviewno", 2);
        },
        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        visitcommentbox(){
            this.visitcomment = !this.visitcomment;
        },
        
    },
}
</script>
<style>
</style>