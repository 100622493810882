<template>
    <div>
        <div>Pdf will be generated {{ progress }}%</div>
        <div>
            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
                margin="[10,10,10,10]" filename="OverduesReport" :paginate-elements-by-height="600" :pdf-quality="2"
                pdf-format="A4" pdf-orientation="portrait" pdf-content-width="800px" :manual-pagination="true"
                @progress="onProgress($event)" @hasPaginated="hasPaginated()" @beforeDownload="beforeDownload($event)"
                @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">

                <section slot="pdf-content">
                    <section class="pdf-item">
                        <h5 style="text-align:center ;">V.S. DigiWorld</h5>
                        <h6 style="text-align:center ;">
                            Dues Report
                        </h6>
                        <div>
                            <div class="panel-body">
                                <table class="table users-table table-condensed table-hover table-sm table-bordered">
                                    <thead class="table-font pdf-content-width" style="position: sticky;">
                                        <tr>
                                            <th>#</th>
                                            <th>LAN No.</th>
                                            <th>Customer Name</th>
                                            <th>Contact</th>
                                            <th>Address</th>
                                            <!-- <th>Landmark</th> -->
                                            <th>Product</th>
                                            <th>Emi Amt</th>
                                            <th>Late Amt</th>
                                            <th>Rem. Amt</th>
                                            <th>Emi Date</th>
                                            <th>Due Date</th>
                                            <th>Day of Delay</th>
                                            <th>Follow By</th>
                                            <th>C. Type</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-font" v-for="(item, index) in scheduleloanemi" :key="item.id"
                                        style="margin-top:20px;margin-bottom: 20px;">

                                        <tr>
                                            <td style="width:20px">{{ (index + 1) }} </td>
                                            <td>
                                                <a v-if="item.loanemi.loanaccount != null" href="#">
                                                    <div
                                                        v-if="item.loanemi != null && item.loanemi.loanaccount != null">
                                                        <span style="font-size: 10px">
                                                            {{ item.loanemi.loanaccount.manualid }}</span>
                                                    </div>
                                                </a>
                                            </td>
                                            <td>
                                                <span v-if="item.loanemi != null &&
            item.loanemi.loanaccount != null &&
            item.loanemi.loanaccount.customer != null
            ">
                                                    {{ item.loanemi.loanaccount.customer.name }}</span>
                                            </td>
                                            <td>
                                                <display-mobiles v-if="item.loanemi.loanaccount.customer != null"
                                                    :mobiles="item.loanemi.loanaccount.customer.mobiles"></display-mobiles>
                                            </td>
                                            <td>
                                                <display-first-address v-if="item.loanemi.loanaccount.customer != null"
                                                    :addresses="item.loanemi.loanaccount.customer.addressess"></display-first-address>
                                            </td>
                                            <!-- <td style="width: 50px;">
                                    <div v-if="item.loanemi.loanaccount.customer != null && item.loanemi.loanaccount.customer.addressess!=null">
                                        <span v-for="address in item.loanemi.loanaccount.customer.addressess" :key="address.id"> 
                                            {{address.landmark}}
                                        </span>
                                    </div>
                                </td> -->
                                            <td style="font-size:8px ;">
                                                <div v-for="item2 in item.loanemi.loanaccount.saleitems"
                                                    :key="item2.id">
                                                    <div>
                                                        <span
                                                            v-if="item2.product != null && item2.product.category != null">{{ item2.product.category.name }}</span>
                                                        <span
                                                            v-if="item2.product != null && item2.product.attribute != null">
                                                            <div v-for="company in item2.product.attribute"
                                                                :key="company.id">
                                                                {{ getcompany(company.attribute_value_ids) }}
                                                            </div>
                                                        </span>
                                                        <span>{{ item2.product.name }}</span>&nbsp;
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span v-if="item.loanemi != null"><i class="bx bx-rupee"></i> {{
            item.loanemi.tobepaid }}</span>
                                            </td>
                                            <td></td>
                                            <td>
                                                <span v-if="item.loanemi != null && item.loanemi.loanaccount != null"
                                                    style="color:red;font-weight:700">
                                                    {{ item.loanemi.loanaccount.remainingamount }}
                                                </span>
                                            </td>
                                            <td>
                                                <span><i class="bx bxs-calendar"></i>
                                                    {{ moment(item.emidate).format("DD-MM-YYYY") }}</span>
                                            </td>
                                            <td>
                                                <span v-if="item.loanemi != null"><i class="bx bxs-calendar"></i>
                                                    {{ moment(item.scheduleddate).format("DD-MM-YYYY") }}</span>
                                            </td>
                                            <td>
                                                {{ bucket(item) }}
                                            </td>
                                            <td>
                                                <div v-if="item.loanemi.loanaccount != null &&
            item.loanemi.loanaccount.loanfollowed != null">
                                                    {{ item.loanemi.loanaccount.loanfollowed.name }}
                                                </div>
                                            </td>
                                            <td><span
                                                    v-if="item.loanemi != null && item.loanemi.loanaccount != null && item.loanemi.loanaccount.customer != null">
                                                    {{ item.loanemi.loanaccount.customer.customer_type }} </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="18" style="background-color:#f2f2f2;padding:0px">
                                                <dues-comment :emiid="item.emiid" :loanid="item.loanemi.loanaccountid"
                                                    :comment="getcomment(item.emiid)"
                                                    :overdueamount="getoverdueamount(item.loanemi.loanaccountid)"></dues-comment>
                                            </td>
                                        </tr>
                                        <div v-if="page1(index)" class="html2pdf__page-break"
                                            style="margin-bottom:20px;">

                                        </div>
                                        <!-- <tr  v-if="page1(index)">
                                <td colspan="18" class="table ">
                                        <thead class="table-font pdf-content-width" style="position: sticky;">
                                    <tr>
                                        <th>#</th>
                                        <th>LAN No.</th>
                                        <th>Customer Name</th>
                                        <th>Contact</th>
                                        <th>Address</th>
                                        <th>Landmark</th>
                                        <th>Product</th>
                                        <th>Emi Amt</th>
                                        <th>Late Amt</th>
                                        <th>Rem. Amt</th>
                                        <th>Emi Date</th>
                                        <th>Due Date</th>
                                        <th>Day of Delay</th>
                                        <th>Fallow By</th>
                                        <th>Customer Type</th>
                                    </tr>
                                        </thead>
                                </td>
                            </tr> -->
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <label>Total Dues Amt.: </label>
                                    <span> {{ totalduescount }}</span>
                                </div>
                                <div class="col-md-4">
                                    <label>Total EMI Amt.: </label>
                                    <span>{{ totalemiamount }}</span>
                                </div>
                                <div class="col-md-4">
                                    <label>Total Remaining : </label>
                                    <span>{{ remainingamount }}</span>
                                </div>
                            </div>
                            <div class="col-md-12 flex-center-row mt-20">
                                <div>
                                    <label>Total Rec.: </label>
                                    <span> {{ totalschedule }}</span>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

            </vue-html2pdf>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf'
import Constants from '../../components/utilities/Constants.vue';
import { mapGetters } from 'vuex';
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import DuesComment from './DuesComment.vue';
export default {
    mixins: [Constants],
    props: ["scheduleloanemi", "overdues", "comments", "totalduescount", "totalschedule", "totalemiamount", "remainingamount"],
    components: {
        VueHtml2pdf,
        DisplayFirstAddress,
        DisplayMobiles,
        DuesComment,

    },
    data() {
        return {
            moment: moment,
            progress: 0,
            loanids: []
        }
    },
    mounted() {
        this.refresh()
    },
    computed: {
        ...mapGetters(["edititem", "viewno",
            "activeemployees", "attributes", "accountstatus", "loanaccounttype"]),
        noofpages() {
            return Math.ceil(parseInt(this.scheduleloanemicount) / parseInt(this.noofrec));
        },
    },

    methods: {
        page1(index) {
            if (index != 0 && index == 7) {
                return true
            }
            if ((index - 7) % 9 == 0) {
                return true
            }
            return false
        },
        getoverdueamount(id) {
            if (this.overdues != null) {
                return this.overdues.find(a => {
                    if (a.loanaccountid == id) {
                        return a.overdue
                    }
                })
            }
        },
        getcomment(id) {
            if (this.comments != null) {
                return this.comments.find(a => {
                    if (a.emiid == id) {
                        return a
                    }
                })
            }
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        bucket(item) {
            const currentdate = moment().toDate("YYYY-MM-DD");
            const diffInDays = moment(currentdate).diff(moment(item.emidate), "months");
            if (diffInDays < 0) {
                return 0;
            } else {
                return diffInDays;
            }
        },
        activate() {
            setTimeout(() => this.generateReport(), 1000);
        },
        refresh() {
            this.$store.dispatch('fetchactiveemployees')
            this.$store.dispatch("fetchattributes")
            console.log("activated")
            setTimeout(() => this.generateReport(), 5000);

        },


        getcompany(attribute_value_ids) {
            let brand = this.attributes.find((block) => block.id == +2);
            if (typeof brand !== "undefined") {
                let companyname = brand.predefinedvalues.find(
                    (a) => a.id == attribute_value_ids
                );
                if (typeof companyname != "undefined") {
                    return companyname.value;
                }
            }
            return attribute_value_ids;
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded() {
            this.$parent.generate = false
        }
    },

}
</script>
<style scoped>
.table-font {
    font-size: 9px !important;
    color: black;
    font-weight: 500;
}
</style>