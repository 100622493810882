<template>
	<div>
		<div class="flex-between-row" style="padding: 10px;">
			<div class="col-md-3"><span class="label">OverDues : </span> {{ showoverdue() }}</div>
			<div class="col-md-3"><span class="label">Comment :</span>
				<span>{{ showcomment() }}</span>
			</div>
			<div class="col-md-3">
				<span class="label">Entry Date :</span>{{ showdate() }}
			</div>
			<div class="col-md-3">
				<span class="label">Entry By :</span>{{ showenteredby() }}
			</div>
		</div>
	</div>
</template>
<script>
import moment from "moment";
export default {
	props: ["emiid", "loanid", "overdueamount", "comment"],
	data() {
		return {
			moment: moment,
			overamount: 0.00
		};
	},
	mounted() {
		// this.fetchdues();
		console.log(this.comment)
	},
	methods: {
		showoverdue() {
			if (typeof this.overdueamount !== 'undefined') {
				return this.overdueamount.overdue
			} else {
				return "0.00"
			}
		},
		showcomment() {
			if (typeof this.comment !== 'undefined') {
				return this.comment.comments
			} else {
				return "N.A"
			}
		},
		showdate() {
			if (typeof this.comment !== 'undefined') {
				return moment(this.comment.created_at).format("DD-MM-YYYY") + "  " + moment(this.comment.created_at).format("hh:mm:s A");
			} else {
				return "N.A"
			}
		},
		showenteredby() {
			if (typeof this.comment !== 'undefined' && this.comment.enteredby != null) {
				return this.comment.enteredby.name;
			} else {
				return "N.A"
			}
		},
		processDetailResponse(data) {
			// this.$store.commit("assignlist", data);
			console.log(data)
			this.comment = data
		},
		processOverduesResponse(data) {
			this.overamount = data
		},
		cancel() {
			this.$parent.visitcomment = false;
		},
	},
};
</script>
<style scoped>
.table-comment {
	margin-bottom: 0;
	font-size: 14px !important;
}

.label {
	font-weight: 700;
}

.table-hover tbody tr:hover {
	background-color: none;
}
</style>
